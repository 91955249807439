import { template as template_8b1829906fa341e5a1d4e5255230df49 } from "@ember/template-compiler";
const WelcomeHeader = template_8b1829906fa341e5a1d4e5255230df49(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
